<template>
  <div>
    <div v-for="(notes, idx) in notes" :key="idx">
      <div class="info-note">
        <label>{{ notes.firstName}} {{notes.lastName}}</label>
        <label>{{ $dateString(notes.createdAt) }}</label>
      </div>
      <el-input
        style="margin-left: 20px;"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4}"
        v-html="notes.reason"
      />
      <el-divider/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patientId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      notes: [],
    };
  },
  methods: {
    async getNotes() {
      try {
        const notes = await this.axios.get(`patient/ophtha/getNotes/${this.patientId}`);
        if (notes.data) {
          this.notes = notes.data;
        }
      } catch (error) {
        // some code here
      }
    },
  },
  async created() {
    await this.getNotes();
  },
};
</script>

<style>
  .info-note {
    letter-spacing: 0.5px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
</style>
