<template>
  <div class="timeline-Content">
    <div class="block">
      <label>{{ $dateString(optometricDate) }}</label>
      <table class="refraction-titles">
        <th />
        <th>Ojo derecho</th>
        <th>Ojo Izquierdo</th>
        <tr>
          <td @click="abrirDialog('AVSC')">{{optometriaData[0].name}}</td>
          <td>{{optometriaData[0].OD}}</td>
          <td> {{optometriaData[0].OI}}</td>
        </tr>
        <tr @click="abrirDialog('AVCC')">
          <td>{{optometriaData[1].name}}</td>
          <td>{{optometriaData[1].OD}}</td>
          <td>{{optometriaData[1].OI}}</td>
        </tr>
        <tr @click="abrirDialog('Presión ocular')">
          <td>{{optometriaData[2].name}}</td>
          <td>{{optometriaData[2].OD}}</td>
          <td>{{optometriaData[2].OI}}</td>
        </tr>
      </table>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%">
        <span style="font-size:22px;font-weight:bold;color:#78499fdf;">
          Historial de {{ title }}
        </span>
        <div class="historyOptions">
          <div
            style="display:grid;"
            v-for="(data, idx) in dialogInfo"
            :key="idx"
          >
            <h1 v-if="data.length < 0">Sin datos previos</h1>
            <strong v-if="data.length > 0">{{ $dateString(data[0].createdAt) }}</strong>
            <table class="refraction-titles" v-if="data.length > 0">
              <th>Ojo Derecho</th>
              <th>Ojo Izquierdo</th>
              <tr v-if="title === 'AVSC'">
                <td class="cellTableDialog">{{data[0].vaNoCorrection}}</td>
                <td class="cellTableDialog">{{data[1].vaNoCorrection}}</td>
              </tr>
              <tr v-if="title === 'AVCC'">
                <td class="cellTableDialog">{{data[0].vaWithCorrection}}</td>
                <td class="cellTableDialog">{{data[1].vaWithCorrection}}</td>
              </tr>
              <tr v-if="title === 'Presión ocular'">
                <td class="cellTableDialog">{{data[0].intraocularPressure}}</td>
                <td class="cellTableDialog">{{data[1].intraocularPressure}}</td>
              </tr>
            </table>
            <el-divider />
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            style="background-color: #78499fdf; color:#fff;" @click="dialogVisible = false">
            Salir
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

const moment = require('moment');

export default {
  props: {
    appointmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      optometricDate: null,
      typeSelected: null,
      title: null,
      dialogVisible: false,
      reverse: true,
      optometriaData:
      [
        {
          name: 'AVSC',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'AVCC',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Presión ocular',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      refraction:
      [
        {
          name: 'Esfera',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Cilindro',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Eje',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          name: 'Adición',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      dialogInfo: [],
    };
  },
  methods: {
    abrirDialog(title) {
      this.title = title;
      this.dialogVisible = true;
      this.typeSelected = title;
    },
    handleClose(done) {
      this.$confirm('Are you sure to close this dialog?')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    async getDataOptometria() {
      try {
        const patientId = this.$route.params.id;
        const response2 = await this.axios.get(`patient/opto/details/${patientId}/${this.appointmentId}`);
        this.optometricDate = moment(response2?.data?.createdAt).format('YYYY-MM-DD');
        // Datos de Optometría
        this.optometriaData[0].OD = response2.data.eyes[0].vaNoCorrection;
        this.optometriaData[1].OD = response2.data.eyes[0].vaWithCorrection;
        this.optometriaData[2].OD = response2.data.eyes[0].intraocularPressure;

        this.optometriaData[0].OI = response2.data.eyes[1].vaNoCorrection;
        this.optometriaData[1].OI = response2.data.eyes[1].vaWithCorrection;
        this.optometriaData[2].OI = response2.data.eyes[1].intraocularPressure;

        for (let i = 0; i < this.optometriaData.length; i++) {
          if (this.optometriaData[i].OD === null) {
            this.optometriaData[i].OD = 'Sin alteraciones';
          }
          if (this.optometriaData[i].OI === null) {
            this.optometriaData[i].OI = 'Sin alteraciones';
          }
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
    async getHistory() {
      try {
        const patientId = this.$route.params.id;
        const res = await this.axios.get(`patient/opto/history/${patientId}/`);
        if (res.data) {
          const datos = res.data;
          this.dialogInfo = _.groupBy(datos, 'evaluationId');
        }
      } catch (error) {
        // some code
      }
    },
  },
  async created() {
    await this.getDataOptometria();
    await this.getHistory();
  },
};
</script>

<style lang="scss">
.historyOptions {
  overflow-y: scroll;
  height: 500px;
}

.timeline-Content {
  margin-top: 30px;
  margin-left: 30px;
  font-size: 16px;

  .el-button --primary {
    background-color: #78499fdf;
  }
}
.refraction-titles {
  align-self: center;
  border-radius: 5px;
  th {
    font-weight: bold;
  }
  td:nth-of-type(1) {
    color: #78499fdf;
    width: 100px;
    padding-left: 10px;
    font-weight: bold;
  }
  td:nth-last-child(2) {
    width: 160px;
    text-align:center;
  }
  td:nth-last-child(1) {
    width: 160px;
    text-align:center;
  }
  .cellTableDialog {
    font-weight: bold;
    border-bottom: solid #78499fdf 1.5px;
  }
  th {
    font-weight: 300;
    color:#fff;
    background-color: #78499fdf;
  }
}
</style>
