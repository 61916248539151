<template>
<div style="margin-top: 10px;">
  <Title2
    v-if="appointmentId"
    :btnText="btnText"
    :appointment="appointmentId"
    :title="'EVALUACIÓN OFTALMOLÓGICA'"
    :label="label"
    v-on:before-submit="beforeSubmit"
    :disabled="loading"
    :print="true"
    enableOptoDialog
    :createdAt="createdAt"
    :render="render"
  />
  <div
    class="opcionesMedicas"
    style="background: #ffff; margin-top: 5px; text-align: center;
    margin-bottom: 3px;"
  >
    <div style="margin-bottom: 5px;">
      <br>
      <strong> Menú de opciones </strong>
      <vue-Html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1100"
        filename="HISTORIAL OFTALMOLOGÍA"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <pdfContentNuevo slot="pdf-content" :appointment="evaluationId"/>
      </vue-html2pdf>
      <el-button
        size="mini"
        @click="downloadPdf()"
      >
      Imprimir Evalución
      </el-button>
      <!-- <el-button
        size="mini"
        @click="newDownloadPdf()"
      >
      Imprimir Evalución formato nuevo
      </el-button> -->
      <el-button size="mini" @click="centerDialogVisible = true">
        Visualizar Documentos
      </el-button>
    </div>
  </div>
  <el-divider />
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="50%"
    center>
      <div>
        <Docs
          :patientId="patientId"
          v-on:close-dialog="closeDialog()"
        />
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">Cerrar</el-button>
    </span>
  </el-dialog>
<el-row
  id="ophthalmologicEvaluation"
  style="height:100%"
>
  <el-col :span="15" class="col">
    <div class="form">
      <el-container>
        <el-main>
          <el-form
            :model="form"
            ref="refId"
            :rules="rules"
            @submit.prevent
            :disabled="loading"
          >
            <div>
              <p
                style="
                  color: #006EFF;
                  font-weight: bold;
                  font-size: 15px !important;
                  margin-top: 0px;
                "
              >
                VALORACIÓN MÉDICA
              </p>
              <el-form-item
                prop="reason"
                class="large"
                label="Motivo de consulta / Padecimiento actual"
              >
                <!-- <el-input
                  v-model="form.reason"
                  type="textarea"
                  :autosize="true"
                /> -->
                <vue-editor v-model="form.reason" :editorToolbar="customToolbar" ref="andale"/>
              </el-form-item>
            </div>
            <!-- EVA -->
            <p class="title">
              SEGMENTO ANTERIOR
            </p>
            <div class="horizontal-align-form">
              <div>
                <p class="subtitle">Ojo Derecho</p>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.ocularGlobule"
                    label-position="top"
                    class="medium"
                    label="Globo Ocular"
                    size="mini"
                    disabled="true"
                  >
                    <el-select
                      v-model="form.right.anterior.ocularGlobule"
                      @change="selectedProtesis(form.right.anterior.ocularGlobule, 'right')"
                    >
                      <el-option
                        v-for="item in options.ocularGlobule"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentOcularGloOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentOcularGloOD = !showCommentOcularGloOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.ocularGlobuleComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentOcularGloOD"
                >
                  <el-input
                    v-model="form.right.anterior.ocularGlobuleComments"
                  />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.eyelid"
                    label="Parpados"
                    size="mini"
                  >
                    <el-select class="medium" v-model="form.right.anterior.eyelid">
                      <el-option
                        v-for="item in options.eyelids"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentParpadosOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentParpadosOD = !showCommentParpadosOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.eyelidComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentParpadosOD"
                >
                  <el-input
                    v-model="form.right.anterior.eyelidComments"
                  />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.annexes"
                    class="medium"
                    label="Anexos"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.annexes">
                      <el-option
                        v-for="item in options.annexes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                      class="btn-comment"
                      :icon="!showCommentAnexosOD ? 'el-icon-plus' : 'el-icon-minus'"
                      size="mini"
                      @click="showCommentAnexosOD = !showCommentAnexosOD"
                    />
                  </div>
                <el-form-item
                  prop="right.anterior.annexesComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentAnexosOD"
                >
                  <el-input v-model="form.right.anterior.annexesComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.ocularMotility"
                    class="medium"
                    label="Motilidad Ocular"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.ocularMotility">
                      <el-option
                        v-for="item in options.ocularMotility"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentMotilidadOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentMotilidadOD = !showCommentMotilidadOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.ocularMotilityComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentMotilidadOD"
                >
                  <el-input v-model="form.right.anterior.ocularMotilityComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.conjunctiva"
                    class="medium"
                    label="Conjuntiva"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.conjunctiva">
                      <el-option
                        v-for="item in options.conjunctiva"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentConjuntivaOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentConjuntivaOD = !showCommentConjuntivaOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.conjunctivaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentConjuntivaOD"
                >
                  <el-input v-model="form.right.anterior.conjunctivaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.sclera"
                    class="medium"
                    label="Esclera"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.sclera">
                      <el-option-group
                        v-for="group in options.sclera"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentEscleraOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentEscleraOD = !showCommentEscleraOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.scleraComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentEscleraOD"
                >
                  <el-input v-model="form.right.anterior.scleraComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.cornea"
                    class="medium"
                    label="Córnea"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.cornea">
                      <el-option-group
                        v-for="group in options.cornea"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentCorneaOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentCorneaOD = !showCommentCorneaOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.corneaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentCorneaOD"
                >
                  <el-input v-model="form.right.anterior.corneaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.anteriorChamber"
                    class="medium"
                    label="Cámara anterior"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.anteriorChamber">
                      <el-option-group
                        v-for="group in options.anteriorChamber"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentCamaraAntOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentCamaraAntOD = !showCommentCamaraAntOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.anteriorChamberComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentCamaraAntOD"
                >
                  <el-input v-model="form.right.anterior.anteriorChamberComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.iris"
                    class="medium"
                    label="Iris"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.iris">
                      <el-option
                        v-for="item in options.iris"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentIrisOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentIrisOD = !showCommentIrisOD"
                  />
                </div>
                  <el-form-item
                    prop="right.anterior.irisComments"
                    class="large"
                    label="Comentarios"
                    size="mini"
                    v-show="showCommentIrisOD"
                  >
                    <el-input v-model="form.right.anterior.irisComments" />
                  </el-form-item>
                  <div style="display:flex; width:100%">
                    <el-form-item
                      prop="right.anterior.ginioscopy"
                      class="medium"
                      label="Gonioscopía"
                      size="mini"
                    >
                    <el-select v-model="form.right.anterior.ginioscopy">
                      <el-option
                        v-for="item in options.ginioscopy"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentGonOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentGonOD = !showCommentGonOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.ginioscopyComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentGonOD"
                >
                  <el-input v-model="form.right.anterior.ginioscopyComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.anterior.lens"
                    class="medium"
                    label="Cristalino"
                    size="mini"
                  >
                    <el-select v-model="form.right.anterior.lens">
                      <el-option-group
                        v-for="group in options.lens"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentCristalinoOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentCristalinoOD = !showCommentCristalinoOD"
                  />
                </div>
                <el-form-item
                  prop="right.anterior.lensComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentCristalinoOD"
                >
                  <el-input v-model="form.right.anterior.lensComments" />
                </el-form-item>
                <el-form-item
                  prop="right.anterior.other"
                  class="xlarge"
                  label="Otros Padecimientos"
                  size="mini"
                >
                  <el-input
                    v-model="form.right.anterior.other"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 2}"
                  />
                </el-form-item>
              </div>
              <div>
                <p class="subtitle">Ojo Izquierdo</p>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.ocularGlobule"
                    class="medium"
                    label="Globo Ocular"
                    size="mini"
                  >
                    <el-select
                      v-model="form.left.anterior.ocularGlobule"
                      @change="selectedProtesis(form.left.anterior.ocularGlobule, 'left')"
                    >
                      <el-option
                        v-for="item in options.ocularGlobule"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentOcularGloOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentOcularGloOI = !showCommentOcularGloOI"
                  />
                </div>
                  <el-form-item
                    prop="left.anterior.ocularGlobuleComments"
                    class="large"
                    label="Comentarios"
                    size="mini"
                    v-show="showCommentOcularGloOI"
                  >
                    <el-input v-model="form.left.anterior.ocularGlobuleComments" />
                  </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item prop="left.anterior.eyelid" label="Parpados" size="mini">
                    <el-select class="medium" v-model="form.left.anterior.eyelid">
                      <el-option
                        v-for="item in options.eyelids"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentParpadosOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentParpadosOI = !showCommentParpadosOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.eyelidComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentParpadosOI"
                >
                  <el-input v-model="form.left.anterior.eyelidComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.annexes"
                    class="medium"
                    label="Anexos"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.annexes">
                      <el-option
                        v-for="item in options.annexes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentAnexosOI? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentAnexosOI = !showCommentAnexosOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.annexesComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentAnexosOI"
                >
                  <el-input v-model="form.left.anterior.annexesComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.ocularMotility"
                    class="medium"
                    label="Motilidad Ocular"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.ocularMotility">
                      <el-option
                        v-for="item in options.ocularMotility"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentMotilidadOI? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentMotilidadOI = !showCommentMotilidadOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.ocularMotilityComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentMotilidadOI"
                >
                  <el-input v-model="form.left.anterior.ocularMotilityComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.conjunctiva"
                    class="medium"
                    label="Conjuntiva"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.conjunctiva">
                      <el-option
                        v-for="item in options.conjunctiva"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentConjuntivaOI? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentConjuntivaOI = !showCommentConjuntivaOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.conjunctivaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentConjuntivaOI"
                >
                  <el-input v-model="form.left.anterior.conjunctivaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.sclera"
                    class="medium"
                    label="Esclera"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.sclera">
                      <el-option-group
                        v-for="group in options.sclera"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentEscleraOI? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentEscleraOI = !showCommentEscleraOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.scleraComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentEscleraOI"
                >
                  <el-input v-model="form.left.anterior.scleraComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.cornea"
                    class="medium"
                    label="Córnea"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.cornea">
                      <el-option-group
                        v-for="group in options.cornea"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentCorneaOI? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentCorneaOI = !showCommentCorneaOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.corneaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentCorneaOI"
                >
                  <el-input v-model="form.left.anterior.corneaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.anteriorChamber"
                    class="medium"
                    label="Cámara anterior"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.anteriorChamber">
                      <el-option-group
                        v-for="group in options.anteriorChamber"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentCamaraAntOI? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentCamaraAntOI = !showCommentCamaraAntOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.anteriorChamberComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentCamaraAntOI"
                >
                  <el-input v-model="form.left.anterior.anteriorChamberComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.iris"
                    class="medium"
                    label="Iris"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.iris">
                      <el-option
                        v-for="item in options.iris"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentIrisOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentIrisOI = !showCommentIrisOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.irisComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentIrisOI"
                >
                  <el-input v-model="form.left.anterior.irisComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.ginioscopy"
                    class="medium"
                    label="Gonioscopía"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.ginioscopy">
                      <el-option
                        v-for="item in options.ginioscopy"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentGonOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentGonOI = !showCommentGonOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.ginioscopyComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentGonOI"
                >
                  <el-input v-model="form.left.anterior.ginioscopyComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.anterior.lens"
                    class="medium"
                    label="Cristalino"
                    size="mini"
                  >
                    <el-select v-model="form.left.anterior.lens">
                      <el-option-group
                        v-for="group in options.lens"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentCristalinoOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentCristalinoOI = !showCommentCristalinoOI"
                  />
                </div>
                <el-form-item
                  prop="left.anterior.lensComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentCristalinoOI"
                >
                  <el-input v-model="form.left.anterior.lensComments" />
                </el-form-item>

                <el-form-item
                  prop="left.anterior.other"
                  class="xlarge"
                  label="Otros Padecimientos"
                  size="mini"
                >
                  <el-input
                    v-model="form.left.anterior.other"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 2}"
                  />
                </el-form-item>
              </div>

            </div>

            <p class="title">
              SEGMENTO POSTERIOR
            </p>
            <div class="horizontal-align-form">
              <div>
                <p class="subtitle">Ojo Derecho</p>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.retina"
                    class="medium"
                    label="Retina general"
                    size="mini"
                  >
                    <el-select
                      v-model="form.right.posterior.retina"
                      @change="selectedRetinaNoValorable(form.right.posterior.retina,'right')"
                    >
                      <el-option
                        v-for="item in options.retina"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentRetinaGeneralOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentRetinaGeneralOD = !showCommentRetinaGeneralOD"
                  />
                </div>
                <el-form-item
                  prop="right.posterior.retinaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentRetinaGeneralOD"
                >
                  <el-input v-model="form.right.posterior.retinaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.vitreo"
                    class="medium"
                    label="Vitreo"
                    size="mini"
                  >
                    <el-select v-model="form.right.posterior.vitreo">
                      <el-option-group
                        v-for="group in options.vitreo"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentVitreoOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentVitreoOD = !showCommentVitreoOD"
                  />
                </div>
                <el-form-item
                  prop="right.posterior.vitreoComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentVitreoOD"
                >
                  <el-input v-model="form.right.posterior.vitreoComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.cupDisc"
                    class="medium"
                    label="Relación Copa/Disco"
                    size="mini"
                  >
                    <el-input v-model="form.right.posterior.cupDisc" />
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentRelacionCDOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentRelacionCDOD = !showCommentRelacionCDOD"
                  />
                </div>
                <el-form-item
                  prop="right.posterior.cupDiscComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentRelacionCDOD"
                >
                  <el-input v-model="form.right.posterior.cupDiscComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.nerve"
                    class="medium"
                    label="Nervio"
                    size="mini"
                  >
                    <el-select v-model="form.right.posterior.nerve">
                      <el-option
                        v-for="item in options.nerve"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentNervioOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentNervioOD = !showCommentNervioOD"
                  />
                </div>
                <el-form-item
                  prop="right.posterior.nerveComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentNervioOD"
                >
                  <el-input v-model="form.right.posterior.nerveComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.macula"
                    class="medium"
                    label="Mácula"
                    size="mini"
                  >
                    <el-select v-model="form.right.posterior.macula">
                      <el-option
                        v-for="item in options.macula"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentMaculaOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentMaculaOD = !showCommentMaculaOD"
                  />
                </div>
                <el-form-item
                  prop="right.posterior.maculaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentMaculaOD"
                >
                  <el-input v-model="form.right.posterior.maculaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.vessel"
                    class="medium"
                    label="Vasos"
                    size="mini"
                  >
                    <el-select v-model="form.right.posterior.vessel">
                      <el-option-group
                        v-for="group in options.vessel"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentVasosOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentVasosOD = !showCommentVasosOD"
                  />
                </div>
                <el-form-item
                  prop="right.posterior.vesselComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentVasosOD"
                >
                  <el-input v-model="form.right.posterior.vesselComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="right.posterior.periphery"
                    class="medium"
                    label="Periferia"
                    size="mini"
                  >
                    <el-select v-model="form.right.posterior.periphery">
                      <el-option
                        v-for="item in options.periphery"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentPeriferiaOD ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentPeriferiaOD = !showCommentPeriferiaOD"
                  />
                </div>
                  <el-form-item
                    prop="right.posterior.peripheryComments"
                    class="large"
                    label="Comentarios"
                    size="mini"
                  v-show="showCommentPeriferiaOD"
                  >
                    <el-input v-model="form.right.posterior.peripheryComments" />
                  </el-form-item>
                <el-form-item
                  prop="right.posterior.interpretationOfDiagnosis"
                  class="large"
                  label="Interpretación de Estudios de Diagnóstico"
                  size="mini"
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                    v-model="form.right.posterior.interpretationOfDiagnosis"
                  />
                </el-form-item>
                <p class="subtitle">Diagnóstico Estadístico</p>
                <div class="right-posterior-options">
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisHealthy"
                  >
                    Sano
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisSquint"
                  >
                    Estrabismo
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisCataracts"
                  >
                    Cataratas
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisGlaucoma"
                  >
                    Glaucoma
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisDiabeticRetinopathy"
                  >
                    Retinopatía Diabética
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisCornealTransplant"
                  >
                    Transplante de Córnea
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisPostOperated"
                  >
                    Post-Operado
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.right.posterior.statisticalDiagnosisOther"
                  >
                    Otros
                  </el-checkbox>
                </div>
                <el-form-item
                  prop="right.posterior.diagnosis"
                  class="midlarge"
                  label="Diagnósticos"
                >
                  <el-input
                    v-model="form.right.posterior.diagnosis"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
                <el-form-item prop="right.posterior.plan" class="midlarge" label="Plan">
                  <el-input
                    v-model="form.right.posterior.plan"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
                <p class="subtitle">Diagnóstico Quirúrgico</p>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisCataracs"
                >
                  Cx Catarata
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisPterygium"
                >
                  Cx Pterigion
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisInjurySuturing"
                >
                  Cierre Herida
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisCornealTransplant"
                >
                  Trasplante de córnea
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisRetina"
                >
                  Cx Retina
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisRefractive"
                >
                  Cx Refractiva
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisStrabismus"
                >
                  Cx Estrabismo
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisGlaucoma"
                >
                  Cx Glaucoma
                </el-checkbox>
                <el-checkbox
                  v-model="form.right.posterior.surgicalDiagnosisOther"
                >
                  Otra cirugía
                </el-checkbox>
                <el-form-item
                  prop="right.posterior.surgicalPlan"
                  class="midlarge"
                  label="Plan Quirúrgico"
                >
                  <el-input
                    v-model="form.right.posterior.surgicalPlan"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
              </div>
              <div>
                <p class="subtitle">Ojo Izquierdo</p>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.retina"
                    class="medium"
                    label="Retina general"
                    size="mini"
                  >
                    <el-select
                      v-model="form.left.posterior.retina"
                      @change="selectedRetinaNoValorable(form.left.posterior.retina,'left')"
                    >
                      <el-option
                        v-for="item in options.retina"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentRetinaGeneralOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentRetinaGeneralOI = !showCommentRetinaGeneralOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.retinaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentRetinaGeneralOI"
                >
                  <el-input v-model="form.left.posterior.retinaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.vitreo"
                    class="medium"
                    label="Vitreo"
                    size="mini"
                  >
                    <el-select v-model="form.left.posterior.vitreo">
                      <el-option-group
                        v-for="group in options.vitreo"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentVitreoOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentVitreoOI = !showCommentVitreoOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.vitreoComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentVitreoOI"
                >
                  <el-input v-model="form.left.posterior.vitreoComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.cupDisc"
                    class="medium"
                    label="Relación Copa/Disco"
                    size="mini"
                  >
                    <el-input v-model="form.left.posterior.cupDisc" />
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentRelacionCDOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentRelacionCDOI = !showCommentRelacionCDOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.cupDiscComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentRelacionCDOI"
                >
                  <el-input v-model="form.left.posterior.cupDiscComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.nerve"
                    class="medium"
                    label="Nervio"
                    size="mini"
                  >
                    <el-select v-model="form.left.posterior.nerve">
                      <el-option
                        v-for="item in options.nerve"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentNervioOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentNervioOI = !showCommentNervioOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.nerveComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentNervioOI"
                >
                  <el-input v-model="form.left.posterior.nerveComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.macula"
                    class="medium"
                    label="Mácula"
                    size="mini"
                  >
                    <el-select v-model="form.left.posterior.macula">
                      <el-option
                        v-for="item in options.macula"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentMaculaOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentMaculaOI = !showCommentMaculaOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.maculaComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentMaculaOI"
                >
                  <el-input v-model="form.left.posterior.maculaComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.vessel"
                    class="medium"
                    label="Vasos"
                    size="mini"
                  >
                    <el-select v-model="form.left.posterior.vessel">
                      <el-option-group
                        v-for="group in options.vessel"
                        :key="group.key"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.ops"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentVasosOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentVasosOI = !showCommentVasosOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.vesselComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentVasosOI"
                >
                  <el-input v-model="form.left.posterior.vesselComments" />
                </el-form-item>
                <div style="display:flex; width:100%">
                  <el-form-item
                    prop="left.posterior.periphery"
                    class="medium"
                    label="Periferia"
                    size="mini"
                  >
                    <el-select v-model="form.left.posterior.periphery">
                      <el-option
                        v-for="item in options.periphery"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn-comment"
                    :icon="!showCommentPeriferiaOI ? 'el-icon-plus' : 'el-icon-minus'"
                    size="mini"
                    @click="showCommentPeriferiaOI = !showCommentPeriferiaOI"
                  />
                </div>
                <el-form-item
                  prop="left.posterior.peripheryComments"
                  class="large"
                  label="Comentarios"
                  size="mini"
                  v-show="showCommentPeriferiaOI"
                >
                  <el-input v-model="form.left.posterior.peripheryComments" />
                </el-form-item>
                <el-form-item
                  prop="left.posterior.interpretationOfDiagnosis"
                  class="large"
                  label="Interpretación de Estudios de Diagnóstico"
                  size="mini"
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                    v-model="form.left.posterior.interpretationOfDiagnosis"
                  />
                </el-form-item>
                <p class="subtitle">Diagnóstico Estadístico</p>
                <div class="left-posterior-options">
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisHealthy"
                  >
                    Sano
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisSquint"
                  >
                    Estrabismo
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisCataracts"
                  >
                    Cataratas
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisGlaucoma"
                  >
                    Glaucoma
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisDiabeticRetinopathy"
                  >
                    Retinopatía Diabética
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisCornealTransplant"
                  >
                    Transplante de Córnea
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisPostOperated"
                  >
                    Post-Operado
                  </el-checkbox>
                  <el-checkbox
                    v-model="form.left.posterior.statisticalDiagnosisOther"
                  >
                    Otros
                  </el-checkbox>
                </div>
                <el-form-item
                  prop="left.posterior.diagnosis"
                  class="midlarge"
                  label="Diagnósticos"
                >
                  <el-input
                    v-model="form.left.posterior.diagnosis"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
                <el-form-item prop="left.posterior.plan" class="midlarge" label="Plan">
                  <el-input
                    v-model="form.left.posterior.plan"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
                <p class="subtitle">Diagnóstico Quirúrgico</p>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisCataracs"
                >
                  Cx Catarata
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisPterygium"
                >
                  Cx Pterigion
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisInjurySuturing"
                >
                  Cierre Herida
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisCornealTransplant"
                >
                  Trasplante de córnea
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisRetina"
                >
                  Cx Retina
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisRefractive"
                >
                  Cx Refractiva
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisStrabismus"
                >
                  Cx Estrabismo
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisGlaucoma"
                >
                  Cx Glaucoma
                </el-checkbox>
                <el-checkbox
                  v-model="form.left.posterior.surgicalDiagnosisOther"
                >
                  Otra cirugía
                </el-checkbox>
                <el-form-item
                  prop="left.posterior.surgicalPlan"
                  class="midlarge"
                  label="Plan Quirúrgico"
                >
                  <el-input
                    v-model="form.left.posterior.surgicalPlan"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
              </div>
            </div>
            <div style="width:100%;">
            <el-form-item
              prop="comments"
              label="Comentarios"
            >
              <el-input
                v-model="form.comments"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
              />
            </el-form-item>

              <!-- <el-form-item style="width: 120px;" label="¿Desea agendar próxima cita?">
                <el-select v-model="date">
                  <el-option
                    v-for="item in options.tf"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item> -->
              <!-- EVA -->

              <el-form-item style="width: 100%!important;margin-top: 10px;">
                <el-button
                  type="primary"
                  @click="beforeSubmit"
                  style="background-color: #6993FF;color:#fff;float:right;"
                >
                  {{btnText}}
                </el-button>
                <!-- <el-button
                  type="primary"
                  v-if="!evaluationId"
                  @click="beforeSubmit"
                  style="background-color: #6993FF;color:#fff;float:right;"
                >
                GUARDAR COMO NUEVA EVALUACIÓN
                </el-button> -->
                <el-button
                  type="success"
                  @click="docsVisible = true"
                  class="files"
                >
                  ADJUNTAR ARCHIVOS
                </el-button>
              </el-form-item>
            </div>
            <DocsDialog
              :docsVisible="docsVisible"
              :folder="'OFTALMOLOGÍA'"
              v-on:close-dialog="closeDialog" />
          </el-form>
        </el-main>
      </el-container>
    </div>
  </el-col>
  <el-col :span="9" class="col">
    <floatNotes v-if="appointmentId" :patientId="patientId" :appointmentId="appointmentId"/>
  </el-col>
</el-row>
</div>
</template>

<script>
import { mapState } from 'vuex';
import { VueEditor } from 'vue2-editor';
import lodash from 'lodash';
import VueHtml2pdf from 'vue-html2pdf';
// import PdfContent from '../../../components/PdfContentEvaluation';
import pdfContentNuevo from '../../../components/PdfNuevoHistorial/formatEvaluationsPrint';
import floatNotes from '../../../components/FloatOpthaEvaluation';
import Title2 from '../../../components/Title2';
import optionsMixin from './mixin/options';
import rulesMixin from './mixin/rules';
import formFormatsMixin from './mixin/formats';
import DocsDialog from '../../Docs/DocsDialog';
import Docs from '../../Docs/index';
// import printApi from '../../../API/pdfPrint';

export default {
  data() {
    return {
      haveData: null,
      customToolbar: [
        [
          'bold',
          'italic',
          'underline',
        ],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
        ],
      ],
      isFirstStatus: false,
      subject: null,
      centerDialogVisible: false,
      render: false,
      patientId: '',
      appointmentId: '',
      docsVisible: false,
      label: 'Por favor llene los datos requeridos para completar la Evaluación',
      btnText: 'REGISTRAR',
      date: false,
      evaluationId: null,
      safeForm: {},
      createdAt: '',
      showCommentOcularGloOD: false,
      showCommentParpadosOD: false,
      showCommentAnexosOD: false,
      showCommentMotilidadOD: false,
      showCommentConjuntivaOD: false,
      showCommentEscleraOD: false,
      showCommentCorneaOD: false,
      showCommentCamaraAntOD: false,
      showCommentIrisOD: false,
      showCommentGonOD: false,
      showCommentCristalinoOD: false,
      showCommentOcularGloOI: false,
      showCommentParpadosOI: false,
      showCommentAnexosOI: false,
      showCommentMotilidadOI: false,
      showCommentConjuntivaOI: false,
      showCommentEscleraOI: false,
      showCommentCorneaOI: false,
      showCommentCamaraAntOI: false,
      showCommentIrisOI: false,
      showCommentGonOI: false,
      showCommentCristalinoOI: false,
      showCommentRetinaGeneralOD: false,
      showCommentVitreoOD: false,
      showCommentNervioOD: false,
      showCommentMaculaOD: false,
      showCommentVasosOD: false,
      showCommentPeriferiaOD: false,
      showCommentRetinaGeneralOI: false,
      showCommentVitreoOI: false,
      showCommentNervioOI: false,
      showCommentMaculaOI: false,
      showCommentVasosOI: false,
      showCommentPeriferiaOI: false,
      showCommentRelacionCDOI: false,
      showCommentRelacionCDOD: false,
      form: {
        reason: '',
        comments: '',
        left: {
          anterior: {
            eyelid: '',
            eyelidComments: '',
            annexes: '',
            annexesComments: '',
            ocularGlobule: '',
            ocularGlobuleComments: '',
            ocularMotility: '',
            ocularMotilityComments: '',
            conjunctiva: '',
            conjunctivaComments: '',
            sclera: '',
            scleraComments: '',
            cornea: '',
            corneaComments: '',
            anteriorChamber: '',
            anteriorChamberComments: '',
            iris: '',
            irisComments: '',
            ginioscopy: '',
            ginioscopyComments: '',
            lens: '',
            lensComments: '',
            other: '',
          },
          posterior: {
            retina: '',
            retinaComments: '',
            vitreo: '',
            vitreoComments: '',
            cupDisc: '',
            cupDiscComments: '',
            nerve: '',
            nerveComments: '',
            macula: '',
            maculaComments: '',
            vessel: '',
            vesselComments: '',
            periphery: '',
            peripheryComments: '',
            statisticalDiagnosisHealthy: false,
            statisticalDiagnosisSquint: false,
            statisticalDiagnosisCataracts: false,
            statisticalDiagnosisGlaucoma: false,
            statisticalDiagnosisDiabeticRetinopathy: false,
            statisticalDiagnosisCornealTransplant: false,
            statisticalDiagnosisPostOperated: false,
            statisticalDiagnosisOther: false,
            diagnosis: '',
            plan: '',
          },
        },
        right: {
          anterior: {
            eyelid: '',
            eyelidComments: '',
            annexes: '',
            annexesComments: '',
            ocularGlobule: '',
            ocularGlobuleComments: '',
            ocularMotility: '',
            ocularMotilityComments: '',
            conjunctiva: '',
            conjunctivaComments: '',
            sclera: '',
            scleraComments: '',
            cornea: '',
            corneaComments: '',
            anteriorChamber: '',
            anteriorChamberComments: '',
            iris: '',
            irisComments: '',
            ginioscopy: '',
            ginioscopyComments: '',
            lens: '',
            lensComments: '',
            other: '',
          },
          posterior: {
            retina: '',
            retinaComments: '',
            vitreo: '',
            vitreoComments: '',
            cupDisc: '',
            cupDiscComments: '',
            nerve: '',
            nerveComments: '',
            macula: '',
            maculaComments: '',
            vessel: '',
            vesselComments: '',
            periphery: '',
            peripheryComments: '',
            statisticalDiagnosisHealthy: false,
            statisticalDiagnosisSquint: false,
            statisticalDiagnosisCataracts: false,
            statisticalDiagnosisGlaucoma: false,
            statisticalDiagnosisDiabeticRetinopathy: false,
            statisticalDiagnosisCornealTransplant: false,
            statisticalDiagnosisPostOperated: false,
            statisticalDiagnosisOther: false,
            diagnosis: '',
            plan: '',
          },
        },
      },
      loading: false,
      loadingService: null,
    };
  },
  mixins: [
    optionsMixin,
    rulesMixin,
    formFormatsMixin,
  ],
  components: {
    Title2,
    Docs,
    DocsDialog,
    VueHtml2pdf,
    // PdfContent,
    pdfContentNuevo,
    floatNotes,
    VueEditor,
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.user.role,
    }),
  },
  methods: {
    selectedProtesis(item, eyeSide) {
      if (item === 'Protesis') {
        // Anterior segmento
        this.form[eyeSide].anterior.annexes = 'No Valorable';
        this.form[eyeSide].anterior.anteriorChamber = 'No Valorable';
        this.form[eyeSide].anterior.conjunctiva = 'No Valorable';
        this.form[eyeSide].anterior.cornea = 'No Valorable';
        this.form[eyeSide].anterior.eyelid = 'No Valorable';
        this.form[eyeSide].anterior.ginioscopy = 'No Valorable';
        this.form[eyeSide].anterior.iris = 'No Valorable';
        this.form[eyeSide].anterior.lens = 'No Valorable';
        this.form[eyeSide].anterior.ocularMotility = 'No Valorable';
        this.form[eyeSide].anterior.sclera = 'No Valorable';

        // Anterior posterior
        this.form[eyeSide].posterior.retina = 'No Valorable';
        this.form[eyeSide].posterior.vitreo = 'No Valorable';
        this.form[eyeSide].posterior.periphery = 'No Valorable';
        this.form[eyeSide].posterior.nerve = 'No Valorable';
        this.form[eyeSide].posterior.macula = 'No Valorable';
        this.form[eyeSide].posterior.cupDisc = 'No Valorable';
        this.form[eyeSide].posterior.vessel = 'No Valorable';
      }
    },
    selectedRetinaNoValorable(item, eyeSide) {
      if (item === 'No Valorable') {
        //  posterior
        this.form[eyeSide].posterior.retina = 'No Valorable';
        this.form[eyeSide].posterior.vitreo = 'No Valorable';
        this.form[eyeSide].posterior.periphery = 'No Valorable';
        this.form[eyeSide].posterior.nerve = 'No Valorable';
        this.form[eyeSide].posterior.macula = 'No Valorable';
        this.form[eyeSide].posterior.cupDisc = 'No Valorable';
        this.form[eyeSide].posterior.vessel = 'No Valorable';
      }
    },
    reInformarDesdeHijo() {
      this.$refs.PdfContent.reInformar();
    },
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    // async newDownloadPdf() {
    //   const request = await printApi.getPDFOftoBYId(this.appointmentId);
    //   console.log(request);
    // },
    closeDialog() {
      this.docsVisible = false;
    },
    async getData(patientId, appointmentId) {
      try {
        const response = await this.axios.get(`patient/ophtha/details/${patientId}/${appointmentId}`);
        if (response.data) {
          const { data } = response;
          this.createdAt = data.createdAt;
          const formatted = this.fullGetFormat(data);
          this.form = formatted;
          this.evaluationId = data.id;
          this.btnText = this.haveData !== false ? 'ACTUALIZAR' : 'REGISTRAR';
          this.safeForm = JSON.parse(JSON.stringify(this.form));
          await this.showComments('right');
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async showComments(eyeSide) {
      const OG = this.form[eyeSide].anterior.ocularGlobuleComments;
      const AC = this.form[eyeSide].anterior.annexesComments;
      const CC = this.form[eyeSide].anterior.anteriorChamberComments;
      const CJ = this.form[eyeSide].anterior.conjunctivaComments;
      const CR = this.form[eyeSide].anterior.corneaComments;
      const EL = this.form[eyeSide].anterior.eyelidComments;
      const GC = this.form[eyeSide].anterior.ginioscopyComments;
      const IC = this.form[eyeSide].anterior.irisComments;
      const LC = this.form[eyeSide].anterior.lensComments;
      const OM = this.form[eyeSide].anterior.ocularMotilityComments;
      const SC = this.form[eyeSide].anterior.scleraComments;

      if (eyeSide === 'right') {
        /* eslint no-unused-expressions: [2, { allowTernary: true }] */
        OG == null ? this.showCommentOcularGloOD = false : this.showCommentOcularGloOD = true;
        AC == null ? this.showCommentAnexosOD = false : this.showCommentAnexosOD = true;
        CC == null ? this.showCommentCamaraAntOD = false : this.showCommentCamaraAntOD = true;
        CJ == null ? this.showCommentConjuntivaOD = false : this.showCommentConjuntivaOD = true;
        CR == null ? this.showCommentCorneaOD = false : this.showCommentCorneaOD = true;
        EL == null ? this.showCommentParpadosOD = false : this.showCommentParpadosOD = true;
        GC == null ? this.showCommentGonOD = false : this.showCommentGonOD = true;
        IC == null ? this.showCommentIrisOD = false : this.showCommentIrisOD = true;
        LC == null ? this.showCommentCristalinoOD = false : this.showCommentCristalinoOD = true;
        OM == null ? this.showCommentMotilidadOD = false : this.showCommentMotilidadOD = true;
        SC == null ? this.showCommentEscleraOD = false : this.showCommentEscleraOD = true;
        await this.showComments('left');
      } else {
        /* eslint no-unused-expressions: [2, { allowTernary: true }] */
        OG == null ? this.showCommentOcularGloOI = false : this.showCommentOcularGloOI = true;
        AC == null ? this.showCommentAnexosOI = false : this.showCommentAnexosOI = true;
        CC == null ? this.showCommentCamaraAntOI = false : this.showCommentCamaraAntOI = true;
        CJ == null ? this.showCommentConjuntivaOI = false : this.showCommentConjuntivaOI = true;
        CR == null ? this.showCommentCorneaOI = false : this.showCommentCorneaOI = true;
        EL == null ? this.showCommentParpadosOI = false : this.showCommentParpadosOI = true;
        GC == null ? this.showCommentGonOI = false : this.showCommentGonOI = true;
        IC == null ? this.showCommentIrisOI = false : this.showCommentIrisOI = true;
        LC == null ? this.showCommentCristalinoOI = false : this.showCommentCristalinoOI = true;
        OM == null ? this.showCommentMotilidadOI = false : this.showCommentMotilidadOI = true;
        SC == null ? this.showCommentEscleraOI = false : this.showCommentEscleraOI = true;
      }
    },
    isDefault(key) {
      const keys = [
        'annexes',
        'eyelid',
        'ocularGlobule',
        'ocularMotility',
        'conjunctiva',
        'sclera',
        'cornea',
        'anteriorChamber',
        'iris',
        'nerve',
        'ginioscopy',
        'lens',
        'retina',
        'vitreo',
        'cupDisc',
        'nerve',
        'macula',
        'vessel',
        'periphery',
      ];
      return !(keys.indexOf(key) === -1);
    },
    defaultSelected() {
      // eslint-disable-next-line
      for (const key in this.form.left.anterior) {
        if (this.isDefault(key) && !this.form.left.anterior[key]) {
          this.form.left.anterior[key] = 'Sin Padecimientos';
        }
      }
      // eslint-disable-next-line
      for (const key in this.form.right.anterior) {
        if (this.isDefault(key) && !this.form.right.anterior[key]) {
          this.form.right.anterior[key] = 'Sin Padecimientos';
        }
      }
      // eslint-disable-next-line
      for (const key in this.form.left.posterior) {
        if (this.isDefault(key) && !this.form.left.posterior[key]) {
          this.form.left.posterior[key] = 'Sin Padecimientos';
        }
      }
      // eslint-disable-next-line
      for (const key in this.form.right.posterior) {
        if (this.isDefault(key) && !this.form.right.posterior[key]) {
          this.form.right.posterior[key] = 'Sin Padecimientos';
        }
      }
    },
    beforeSubmit() {
      this.$refs.refId.validate((isValid) => {
        if (isValid) {
          this.submit();
        }
      });
    },
    getDiff(obj1 = {}, obj2 = {}, diff = [], keyMap = '') {
      Object.keys(obj1).forEach((key) => {
        if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
          const diffR = this.getDiff(obj1[key], obj2[key], diff, `${keyMap}${key}.`);
          diff.concat(diffR);
        } else if (!lodash.isEqual(obj1[key], obj2[key])) {
          let keyMapFormatted = `${keyMap}${key}`;
          keyMapFormatted = keyMapFormatted.replace(/[0-9]$/g, '');
          keyMapFormatted = keyMapFormatted[keyMapFormatted.length - 1] === '.'
            ? keyMapFormatted.slice(0, -1) : keyMapFormatted;
          diff.push(keyMapFormatted);
        }
      });
      return diff;
    },
    async submit() {
      try {
        this.loading = true;
        const { id: patientId } = this.$route.params;
        const { idcita: appointmentId } = this.$route.params;
        const diff = this.getDiff(this.form, this.safeForm);
        const requests = [];
        let message = 'Se actualizó perfil oftalmológico';
        if (this.haveData === false) {
          const data = this.fullPostFormat(this.form);
          const response = await this.axios.post(`patient/ophtha/${patientId}/${appointmentId}`, data);
          message = 'Se generó evaluación oftalmológica';
          if (response) {
            this.evaluationId = response.data.evaluationId;
            this.btnText = 'ACTUALIZAR';
            this.safeForm = JSON.parse(JSON.stringify(this.form));
            this.haveData = true;
            if (this.subject != null) await this.axios.put(`patient/subsecuente/updateCreatorSubsecuent/${appointmentId}`);
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'OFTALMOLOGÍA',
              message,
            });
          }
        }

        if (diff.length) {
          if (this.haveData !== false) {
            const data = this.fullPostFormat(this.form);
            const response = await this.axios.put(`patient/ophtha/${patientId}/${appointmentId}/${this.evaluationId}`, data);
            message = 'Se actualizó evaluación oftalmológica';
          }
          if (diff.some((x) => x.split('.')[1] === 'anterior')) {
            const right = diff.some((x) => {
              const s = x.split('.');
              return s[0] === 'right' && s[1] === 'anterior';
            });
            const left = diff.some((x) => {
              const s = x.split('.');
              return s[0] === 'left' && s[1] === 'anterior';
            });
            if (right && left) {
              const data = this.postASFormat(this.form);
              const url = `patient/ophtha/anteriorsegment/${this.evaluationId}`;
              requests.push(this.axios.put(url, data));
            } else {
              if (right) {
                const data = this.form.right.anterior;
                const url = `patient/ophtha/anteriorsegment/single/${this.evaluationId}`;
                requests.push(this.axios.put(url, { eyeSide: 'RIGHT', ...data }));
              }
              if (left) {
                const data = this.form.left.anterior;
                const url = `patient/ophtha/anteriorsegment/single/${this.evaluationId}`;
                requests.push(this.axios.post(url, { eyeSide: 'LEFT', ...data }));
              }
            }
          }
          if (diff.some((x) => x.split('.')[1] === 'posterior')) {
            const right = diff.some((x) => {
              const s = x.split('.');
              return s[0] === 'right' && s[1] === 'posterior';
            });
            const left = diff.some((x) => {
              const s = x.split('.');
              return s[0] === 'left' && s[1] === 'posterior';
            });
            if (right && left) {
              const data = this.postPSFormat(this.form);
              const url = `patient/ophtha/posteriorsegment/${this.evaluationId}`;
              requests.push(this.axios.put(url, data));
            }
            if (right) {
              const data = this.form.right.posterior;
              const url = `patient/ophtha/posteriorsegment/single/${this.evaluationId}`;
              requests.push(this.axios.put(url, {
                ...data,
                eyeSide: 'RIGHT',
              }));
            }
            if (left) {
              const data = this.form.left.posterior;
              const url = `patient/ophtha/posteriorsegment/single/${this.evaluationId}`;
              requests.push(this.axios.put(url, {
                ...data,
                eyeSide: 'LEFT',
              }));
            }
          }
          const response = await Promise.all(requests);
          if (response.length) {
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'OFTALMOLOGÍA',
              message,
            });
          }
        }
        this.$notify({
          type: 'success',
          title: '¡Éxito!',
          message,
        });
        await this.checkedAppointment();
        await this.changeStatus(patientId, this.appointmentId);
        this.$refs.html2Pdf.$children[0].reInformar();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        // this.$errorNotify(error);
      }
    },
    async checkPatient(id) {
      try {
        const response = await this.axios.get(`patient/check-id/${id}`);
        return response.status === 204;
      } catch (error) {
        return false;
      }
    },
    async checkedAppointment() {
      try {
        await this.axios.patch(`patient/subsecuente/check/${this.appointmentId}`);
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async getAppointment(patientId, appointmentId) {
      try {
        this.appointmentId = appointmentId;
        let response = '';
        response = await this.axios.get(`patient/ophtha/getAppointmentSubsecuenteV2/${patientId}/${appointmentId}`);
        const { data } = response;
        if (data.appointment.length > 0) {
          this.haveData = true;
          this.subject = data.appointment[0].subject;
          await this.getData(this.patientId, this.appointmentId);
        } else {
          this.haveData = false;
          const request = await this.axios.get(`patient/ophtha/previous/${patientId}/${appointmentId}`);
          const { appointment } = request.data[0];
          await this.getData(patientId, appointment);
        }
        await this.getStatus(this.patientId, appointmentId);
      } catch (error) {
        this.$notify({
          type: 'info',
          title: 'Nueva evaluación',
          message: 'El expediente no cuenta con evaluación oftalmológica, se generará una nueva evaluación.',
        });
      }
    },
    async changeStatus(patient, appointment) {
      try {
        if (this.isFirstStatus === true) {
          await this.axios.post(`patient/status/${patient}/${appointment}`, {
            statusId: 3,
          });
        } else if (this.statusId !== 3) {
          await this.axios.put(`patient/status/${patient}/${appointment}`, {
            statusId: 3,
          });
        }
      } catch (error) {
        // some code
      }
    },
    async getStatus(patientId, appointmentId) {
      const request = await this.axios(`patient/status/${patientId}/${appointmentId}`);
      if (!request.data) {
        this.isFirstStatus = true;
      } else {
        this.statusId = request.data.id;
      }
    },
  },
  async created() {
    const { id, idcita } = this.$route.params;
    this.patientId = id;
    const isValid = await this.checkPatient(id);
    this.loading = true;
    if (!isValid) {
      this.$notify({
        type: 'warning',
        title: 'Error.',
        message: 'Parámetro no válido.',
        timeout: 2000,
      });
      this.$router.replace({ path: '/' });
    }
    this.defaultSelected();
    await this.getAppointment(id, idcita);
    this.loading = false;
  },
  watch: {
    loading(val) {
      if (val && !this.loadingService) {
        this.loadingService = this.$loading({
          fullscreen: true,
          lock: true,
          text: 'Cargando',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      } else if (this.loadingService) {
        this.loadingService.close();
        this.loadingService = null;
      }
    },
  },
};
</script>
<style lang="scss">
@import './styles/style.scss';
.col-sticky {
  position: absolute;
  top: 10;
  right: 0;
}
vue-editor {
  border-radius: 13px;
}
// .diag-estadistico {
//   background-color: rgb(238, 238, 238);
//   display: grid;
//   grid-template-columns: 2;
// }
</style>
<style lang="scss" scoped>
@import './styles/style-scoped.scss';
</style>
