<template>
<div class="container-float">
  <el-collapse v-model="activeName" accordion>
    <!-- <el-collapse-item title="Comentarios Generales" name="1">
      <Comments :patientId="patientId" />
    </el-collapse-item> -->
    <el-collapse-item title="Perfil optométrico" name="1">
      <div>
        <Profile :patientId="patientId" />
      </div>
    </el-collapse-item>
    <el-collapse-item title="Historial optométrico" name="2">
      <timelineAppointments :patientId="patientId" :appointmentId="appointmentId"/>
    </el-collapse-item>
    <el-collapse-item title="Notas" name="3">
      <Notas :patientId="patientId" style="overflow-y: scroll; max-height: 50vh;"/>
    </el-collapse-item>
  </el-collapse>
</div>
</template>

<script>
import Profile from '../views/profiles/newProfiles/opto';
// import Comments from '../views/patient/Comments';
import timelineAppointments from './timelineAppointments';
import Notas from './Notas';

export default {
  props: {
    patientId: {
      type: String,
      default: null,
    },
    appointmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeName: '0',
    };
  },
  components: {
    Profile,
    // Comments,
    timelineAppointments,
    Notas,
  },
  methods: {
    changeColor(name) {
      const btnName = document.getElementById(name);
      btnName.style.backgroundColor = '#000000';
    },
  },
  async created() {
    await this.getOptoData();
  },
};
</script>

<style lang="scss">
.tabsFlotat {
  display: flex;
  flex-direction: row;
  // background-color: rgba(184, 68, 68, 0.63);
  justify-content: right;
  .floatBody {
    margin-right: 10px;
  }
  .floatOptions {
    display: grid;
    grid-auto-columns: 1;
    gap: 2px;
  }
  .floatOptions button {
    background-color: transparent;
    border-color: transparent;
    padding: 5px;
    text-align: left;
  }
  .floatOptions button:visited {
    background-color: rgb(134, 75, 75);
    border-color: transparent;
    padding: 5px;
    text-align: left;
  }
}
</style>
