export default {
  data() {
    return {
      rules: {
        reason: [
          {
            required: true,
            trigger: 'blur',
            message: 'El campo es requerido.',
          },
        ],
      },
    };
  },
};
